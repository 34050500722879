



/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

/*@font-face {*/
/*	font-family: Montserrat-Regular;*/
/*	src: url('../fonts/montserrat/Montserrat-Regular.ttf');*/
/*}*/

/*@font-face {*/
/*	font-family: Montserrat-ExtraBold;*/
/*	src: url('../fonts/montserrat/Montserrat-ExtraBold.ttf');*/
/*}*/

/*@font-face {*/
/*	font-family: Montserrat-Bold;*/
/*	src: url('../fonts/montserrat/Montserrat-Bold.ttf');*/
/*}*/


/*//////////////////////////////////////////////////////////////////
[ RESTYLE TAG ]*/

* {
	margin: 0px;
	padding: 0px;
	box-sizing: border-box;
}

body, html {
	display: flex;
	flex-direction: column;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	/*padding: 16px 16px 0px;*/
	min-height: 100%;
	background: linear-gradient(139.73deg, rgb(233 180 115) 0%, rgb(183 160 255) 100%);
}

/*---------------------------------------------*/
a {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
	transition: all 0.4s;
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}

a:hover {
	text-decoration: none;
	color: #fff;
}

/*---------------------------------------------*/
h1,h2,h3,h4,h5,h6 {
	margin: 0px;
}

p {
	font-size: 14px;
	line-height: 1.7;
	color: #666666;
	margin: 0px;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}


/*---------------------------------------------*/
input {
	outline: none;
	border: none;
}

textarea {
	outline: none;
	border: none;
}

textarea:focus, input:focus {
	border-color: transparent !important;
}

input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #999999; }
input:-moz-placeholder { color: #999999; }
input::-moz-placeholder { color: #999999; }
input:-ms-input-placeholder { color: #999999; }

textarea::-webkit-input-placeholder { color: #999999; }
textarea:-moz-placeholder { color: #999999; }
textarea::-moz-placeholder { color: #999999; }
textarea:-ms-input-placeholder { color: #999999; }

/*---------------------------------------------*/
button {
	outline: none !important;
	border: none;
	background: transparent;
}

button:hover {
	cursor: pointer;
}

iframe {
	border: none !important;
}


/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
	font-size: 16px;
	color: #cccccc;
	line-height: 1.4;
}

.bo1 {
	border-bottom: 1px solid #999999;
}

.hov1:hover {
	border-color: #d33f8d;
}


/*//////////////////////////////////////////////////////////////////
[ login ]*/

.limiter {
	width: 100%;
	margin: 0 auto;
}

.container-login100 {
	width: 100%;
	min-height: 100vh;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	padding: 15px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	position: relative;
	z-index: 1;
}

.container-login100::before {
	content: "";
	display: block;
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background: #005bea;
	background: -webkit-linear-gradient(bottom, #005bea, #00c6fb);
	background: -o-linear-gradient(bottom, #005bea, #00c6fb);
	background: -moz-linear-gradient(bottom, #005bea, #00c6fb);
	background: linear-gradient(bottom, #005bea, #00c6fb);
	opacity: 0.9;
}

.wrap-login100 {
	width: 390px;
	background: transparent;
}



/*------------------------------------------------------------------
[  ]*/
.login100-form {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.login100-form-title {
	font-size: 24px;
	color: #fff;
	line-height: 1.2;
	text-align: center;

	width: 100%;
	display: block;
}

/*---------------------------------------------*/
.login100-form-avatar {
	width: 120px;
	height: 120px;
	border-radius: 50%;
	overflow: hidden;
	margin: 0 auto;
}
.login100-form-avatar img {
	width: 100%;
}


/*---------------------------------------------*/
.wrap-input100 {
	position: relative;
	width: 100%;
	z-index: 1;
	margin-bottom: 10px;
}

.input100 {
	font-size: 15px;
	line-height: 1.2;
	color: #333333;

	display: block;
	width: 100%;
	background: #f795d62e;
	height: 50px;
	border-radius: 25px;
	padding: 0 30px 0 53px;
}

*{
	font-family: Kanit, sans-serif;
	-webkit-font-smoothing: antialiased;
}

.participate {
	/* basic */
	background-color: #fff;
	margin: 0 auto;
	/*width: 200px;*/
	/*padding: 100px;*/
	text-align: center;
	/* border-radius */
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	/* box-shadow */
	-webkit-box-shadow: rgb(0 0 0 / 80%) 2px 3px 11px 1px;
	-moz-box-shadow: rgb(0 0 0 / 80%) 2px 3px 11px 1px;
	box-shadow: rgb(0 0 0 / 80%) 2px 3px 11px 1px;
}

.input100::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
	color: dimgrey;
	opacity: 1; /* Firefox */
}

.input100:-ms-input-placeholder { /* Internet Explorer 10-11 */
	color: dimgrey;
}

.input100::-ms-input-placeholder { /* Microsoft Edge */
	color: dimgrey;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
	display: block;
	position: absolute;
	border-radius: 25px;
	bottom: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	box-shadow: 0px 0px 0px 0px;
	color: rgba(0,91,234, 0.6);
}

.input100:focus + .focus-input100 {
	-webkit-animation: anim-shadow 0.5s ease-in-out forwards;
	animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 80px 30px;
		opacity: 0;
	}
}

@keyframes anim-shadow {
	to {
		box-shadow: 0px 0px 80px 30px;
		opacity: 0;
	}
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
	width: 100%;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.login100-form-btn {
	font-size: 15px;
	line-height: 1.5;
	color: #e0e0e0;

	width: 100%;
	height: 50px;
	border-radius: 25px;
	background: #333333;
	display: -webkit-box;
	display: -webkit-flex;
	display: -moz-box;
	display: -ms-flexbox;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 25px;

	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;

	position: relative;
	z-index: 1;
}

.login100-form-btn::before {
	content: "";
	display: block;
	position: absolute;
	z-index: -1;
	width: 100%;
	height: 100%;
	border-radius: 25px;
	top: 0;
	left: 0;
	background: #005bea;
	background: -webkit-linear-gradient(left, #005bea, #00c6fb);
	background: -o-linear-gradient(left, #005bea, #00c6fb);
	background: -moz-linear-gradient(left, #005bea, #00c6fb);
	background: linear-gradient(left, #005bea, #00c6fb);
	-webkit-transition: all 0.4s;
	-o-transition: all 0.4s;
	-moz-transition: all 0.4s;
	transition: all 0.4s;
	opacity: 0;
}

.login100-form-btn:hover {
	background: transparent;
	color: #fff;
}

.login100-form-btn:hover:before {
	opacity: 1;
}

/*------------------------------------------------------------------
[ Button sign in with ]*/
.btn-face,


.btn-face {
	color: #3b5998;
}

.btn-face i {
	font-size: 20px;
	margin-right: 10px;
	padding-bottom: 1px;
}

.btn{
	color: #fff;
	background-color: rgb(159 16 216 / 48%);
	border-color: rgb(159 16 216 / 48%);
}

.btn:hover{
	color: white;
	background-color: rgb(73 18 118);
	border-color: rgb(62 0 255);
}

.btn-face:hover,
.btn-google:hover {
	border-color: #d33f8d;
}



/*------------------------------------------------------------------
[ Alert validate ]*/

.validate-input {
	position: relative;
}

.alert-validate::before {
	content: attr(data-validate);
	position: absolute;
	max-width: 70%;
	background-color: white;
	border: 1px solid #c80000;
	border-radius: 14px;
	padding: 4px 25px 4px 10px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 8px;
	pointer-events: none;

	color: #c80000;
	font-size: 13px;
	line-height: 1.4;
	text-align: left;

	visibility: hidden;
	opacity: 0;

	-webkit-transition: opacity 0.4s;
	-o-transition: opacity 0.4s;
	-moz-transition: opacity 0.4s;
	transition: opacity 0.4s;
}

.alert-validate::after {
	content: "\f06a";
	display: block;
	position: absolute;
	color: #c80000;
	font-size: 15px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	right: 13px;
}

.alert-validate:hover:before {
	visibility: visible;
	opacity: 1;
}

@media (max-width: 992px) {
	.alert-validate::before {
		visibility: visible;
		opacity: 1;
	}
}

/*//////////////////////////////////////////////////////////////////
[ responsive ]*/

@media (max-width: 576px) {
	.wrap-login100 {
		padding-top: 80px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.col-md-4 {
	color: white;
	font-size: 24px;
	/*padding-bottom: 20px;*/
	/*padding-top: 18px;*/
	/*margin-right: 10px;*/
	/*margin-bottom: 10px;*/
	background: #9874e0;
	/*margin-left: 10px;*/
}

/*.col-md-3:nth-child(2n+1) {*/
/*	background: #9874e0;*/
/*}*/

/*.col-md-3:nth-child(2n+2) {*/
/*	background: black;*/
/*}*/

.chfQFH {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

 .jGvODc {
	 height: 100%;
 }

.bJVlVi {
	-webkit-box-align: center;
	align-items: center;
	background-color: rgb(239, 244, 245);
	border-radius: 16px;
	box-shadow: rgb(0 0 0 / 10%) 0px -2px 0px inset;
	display: inline-flex;
	height: 32px;
	padding-left: 40px;
	padding-right: 8px;
	position: relative;
}
.fOPopv {
	 display: flex;
}

.ifVMjR {
	-webkit-box-align: center;
	align-items: center;
	background-color: rgb(250, 249, 250);
	border-color: rgb(201 31 212);
	border-radius: 50%;
	border-style: solid;
	border-width: 2px;
	display: flex;
	height: 40px;
	-webkit-box-pack: center;
	justify-content: center;
	left: 0px;
	position: absolute;
	top: -4px;
	width: 40px;
	z-index: 102;
}
.fjJGoS {
	position: relative;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	color: rgb(122, 110, 170);
	font-size: 16px;
	font-weight: 400;
	padding: 0px 16px;
	height: 48px;
}
.wallet{
	border-color: rgb(201 31 212);
	border-style: solid;
}
.dwUojQ {
	align-self: center;
	fill: rgb(201 31 212);
	flex-shrink: 0;
}
.wallet-img{
	margin-left: -5px;
}
.cnHvGm {
	display: block;
	margin-left: 8px;
	margin-right: 4px;
}
 .cnHvGm {
	 color: rgb(40, 13, 95);
	 font-weight: 600;
 }

.elkeBC {
	position: fixed;
	top: 0px;
	left: 0px;
	transition: top 0.2s ease 0s;
	height: 56px;
	width: 100%;
	z-index: 20;
}

.kKtmBR {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
	width: 100%;
	height: 56px;
	background-color: rgb(255, 255, 255);
	border-bottom: 1px solid rgb(231, 227, 235);
	transform: translate3d(0px, 0px, 0px);
	padding-left: 16px;
	padding-right: 16px;
}
.LIvgw {
	position: relative;
	display: flex;
	-webkit-box-align: center;
	align-items: center;
	color: rgb(118, 69, 217);
	font-size: 16px;
	font-weight: 600;
	padding: 0px 16px;
	height: 48px;
}
.fOPopv {
	display: flex;
}
.hUlRzh {
	margin-left: 24px;
}
.iPIHoo {
	position: relative;
}
.iXqCiO {
	padding-top: 4px;
	padding-bottom: 4px;
}
.iXqCiO {
	padding-top: 4px;
	padding-bottom: 4px;
}
.htheDr {
	display: flex;
	flex-direction: column;
}
.jfTcA-D {
	gap: 22px;
	align-items: flex-start;
}

.jfTcA-D > div:last-child {
	flex: 2 1 0%;
}
 .jfTcA-D > div:first-child {
	 flex: 1 1 0%;
	 gap: 20px;
 }
 .jMqaHv {
	 display: flex;
	 flex-direction: column;
 }
 .gmOXNJ {
	 width: 100%;
 }

.OA-dwz {
	width: 100%;
}
 .hkCvwg {
	 background: rgb(231, 227, 235);
	 border-radius: 24px;
	 color: rgb(40, 13, 95);
	 overflow: hidden;
	 position: relative;
	 padding: 1px 1px 3px;
 }

.isZJSj {
	width: 100%;
	height: 100%;
	overflow: inherit;
	background: rgb(255, 255, 255);
	border-radius: 24px;
}

.gZEYEG {
	display: grid;
	-webkit-box-align: center;
	align-items: center;
	grid-template-columns: 1fr 8fr 1fr;
}
 .epLSSF {
	 border-bottom: 1px solid rgb(231, 227, 235);
	 height: 72px;
	 padding-left: 24px;
	 padding-right: 24px;
 }

.QveLg {
	color: rgb(40, 13, 95);
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
}
.jMtDWj {
	padding: 0px;
	width: 48px;
}
 .eWVwgm {
	 -webkit-box-align: center;
	 align-items: center;
	 border: 0px;
	 border-radius: 16px;
	 cursor: pointer;
	 display: inline-flex;
	 font-family: inherit;
	 font-size: 16px;
	 font-weight: 600;
	 -webkit-box-pack: center;
	 justify-content: center;
	 letter-spacing: 0.03em;
	 line-height: 1;
	 opacity: 1;
	 outline: 0px;
	 transition: background-color 0.2s ease 0s, opacity 0.2s ease 0s;
	 height: 48px;
	 padding: 0px 24px;
	 background-color: transparent;
	 color: rgb(31, 199, 212);
	 box-shadow: none;
	 max-width: 32px;
 }
.eQkZMk {
	padding: 24px;
}

.jdlnRz {
	display: flex;
	-webkit-box-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	align-items: center;
}
.dmGxwu {
	color: rgb(122, 110, 170);
	font-weight: 600;
	line-height: 1.5;
	text-transform: uppercase;
	font-size: 12px;
}
.chfQFH {
	display: flex;
	-webkit-box-align: center;
	align-items: center;
}

.krVkBZ {
	color: rgb(40, 13, 95);
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	text-transform: uppercase;
	margin-right: 4px;
}

.jNuCsa {
	align-self: center;
	fill: rgb(122, 110, 170);
	flex-shrink: 0;
}


.toggle {
	align-items: center;
	border-radius: 100px;
	display: flex;
	font-size: 10px;
	margin-left: 20px;
	color: rgb(122, 110, 170);
}
.toggle__input {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	margin-right: 5px;
	width: 1px;
}
.toggle__input:not([disabled]):active + .toggle-track, .toggle__input:not([disabled]):focus + .toggle-track {
	border: 1px solid transparent;
	box-shadow: 0px 0px 0px 2px #000 0%;
}
.toggle__input:disabled + .toggle-track {
	cursor: not-allowed;
	opacity: 0.7;
}
.toggle-track {
	background: #bea2ef;
	border: 1px solid #000 100%;
	border-radius: 100px;
	cursor: pointer;
	display: flex;
	height: 15px;
	margin-right: 12px;
	position: relative;
	width: 30px;
}
.toggle-indicator {
	align-items: center;
	background: #feecf7 0%;
	border-radius: 12px;
	bottom: 1px;
	top: 1px;
	display: flex;
	height: 12px;
	justify-content: center;
	left: 1px;
	right: 1px;
	outline: solid 2px transparent;
	position: absolute;
	transition: 0.5s;
	width: 12px;
}
.checkMark {
	fill: #fff;
	height: 20px;
	width: 20px;
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
}
.toggle__input:checked + .toggle-track .toggle-indicator {
	background: #e6b47c 0%;
	transform: translateX(13px);
}
.toggle__input:checked + .toggle-track .toggle-indicator .checkMark {
	opacity: 1;
	transition: opacity 0.5s ease-in-out;
}
@media screen and (-ms-high-contrast: active) {
	.toggle-track {
		border-radius: 0;
	}
}
